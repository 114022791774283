import {
  Button,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { isMobileOperatingSystem } from '../../utils/functionsUtil';

const PaymentQRCtaBtnContinue = ({
  handleContinue, disabled, ctaLabel, useLoadingEffect,
}) => {
  const classes = usePaymentQRStyles();

  return (
    <>
      <Grid
        container
        className={classes.ctaBox}
        spacing={isMobileOperatingSystem() ? 2 : 0}
        direction={isMobileOperatingSystem() ? 'column' : 'row-reverse'}
      >
        <Grid item xs={12} md={6}>
          {useLoadingEffect && (
            <>
              {/* TODO: al presionar pagar, efecto de boton procesando like mp */}
              {useLoadingEffect}
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinue}
            disabled={disabled}
            fullWidth
          >
            {ctaLabel}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PaymentQRCtaBtnContinue.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  useLoadingEffect: PropTypes.bool,
};

PaymentQRCtaBtnContinue.defaultProps = {
  useLoadingEffect: false,
};

export default PaymentQRCtaBtnContinue;
