import i18n from '../common/i18n';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

// eslint-disable-next-line import/prefer-default-export
export const HOME_OPTIONS = {
  homeNewLoan: {
    title: i18n.homeNewLoan,
    urlImage: '/images/home-nuevo-prestamo.svg',
    pathOnClick: getPathRouteFromKey(i18n.newLoanKey),
  },
  homeMyLoans: {
    title: i18n.homeLoanInstallments,
    urlImage: '/images/home-mis-prestamos.svg',
    pathOnClick: getPathRouteFromKey(i18n.myLoansKey),
  },
  homePayInstallment: {
    title: i18n.homePayInstallment,
    urlImage: '/images/home-pagar-cuota.svg',
    pathOnClick: getPathRouteFromKey(i18n.payInstallmentKey),
  },
  homeMyCard: {
    title: i18n.homeMyCard,
    urlImage: '/images/home-mi-tarjeta.svg',
    pathOnClick: getPathRouteFromKey(i18n.myCardKey),
  },
  homeMyPayments: {
    title: i18n.homeMyPayments,
    urlImage: '/images/home-mis-pagos.svg',
    pathOnClick: getPathRouteFromKey(i18n.myPaymentsMenuKey),
  },
  homePayService: {
    title: i18n.homePayService,
    urlImage: '/images/home-pago-servicios.svg',
    pathOnClick: `${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=91`,
  },
  homeRecharge: {
    title: i18n.homeRecharge,
    urlImage: '/images/home-recargas.svg',
    pathOnClick: `${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=69`,
  },
  homePayQR: {
    title: i18n.homeQRPayment,
    urlImage: '/images/home-pago-qr.svg',
    pathOnClick: 'TODO: ver donde ir con pago con QR',
  },
};
