import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import PaymentQRArrowBack from './paymentQRCtaArrowBack';
import PaymentQRCtaBtnContinue from './paymentQRCtaBtnContinue';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import i18n from '../../common/i18n';
import Loading from '../../components/common/Loading';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import { usePaymentQRSelectAmount } from '../../services/paymentQRService';
import { usePaymentQRContext } from '../../contexts/PaymentQRContext';

const PaymentQRSelectAmount = () => {
  const classes = usePaymentQRStyles();
  const { paymentQRContext } = usePaymentQRContext();
  const {
    loading, responseError, errorMessage, handleContinue, handleBack, handleInputChange,
    isValidAmount, amountNotAvailableError,
  } = usePaymentQRSelectAmount();

  return (
    <>
      {loading && (
        <Loading />
      )}

      {!loading && !responseError && (
        <>
          <Grid container className={classes.body}>

            <Grid item xs={12}>
              <PaymentQRArrowBack handleBack={handleBack} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <Typography variant="h2" align="left">
                {i18n.PaymentQR.OpenAmount.selectAmountTitle}
              </Typography>
            </Grid>

            {/* TODO: input amount con decimales como superindices */}
            <Grid item xs={12} style={{ paddingTop: '50px' }}>
              <TextField
                variant="outlined"
                name="selectedAmount"
                value={paymentQRContext?.selectedAmount}
                onChange={handleInputChange}
                InputProps={{
                  inputProps: {
                    style: {
                      textAlign: 'center',
                      fontSize: '36px',
                    },
                  },
                  classes: {
                    input: amountNotAvailableError ? classes.red : {},
                    notchedOutline: classes.noBorder,
                  },
                }}
                error={amountNotAvailableError}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              {paymentQRContext?.maxAmountAvailable && (
                <Typography variant="h6" textAlign="center">
                  {`Disponible: ${paymentQRContext.maxAmountAvailable}`}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6}>
              <PaymentQRCtaBtnContinue
                handleContinue={handleContinue}
                disabled={!isValidAmount}
                ctaLabel={i18n.PaymentQR.ctaContinue}
              />
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (
        <Grid container className={classes.body} justifyContent="center">
          <Grid item xs={10} md={6}>
            <PaymentQRMessageResponse
              response={responseError}
              referenceLabels={errorMessage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PaymentQRSelectAmount;
