import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import i18n from '../../common/i18n';
import MessageResponse from '../../components/common/MessageResponse';
import qrReadErrorImg from '../../assets/imgs/qr-read-error.svg';
import qrDebtErrorImg from '../../assets/imgs/qr-debt-error.svg';
import qrInsuficientFundsErrorImg from '../../assets/imgs/qr-insuficient-funds-error.svg';
import {
  PAYMENT_QR_GET_INFO_ERROR,
  PAYMENT_QR_SELECT_AMOUNT_ERROR,
  PAYMENT_QR_CUSTOMER_DEBT_ERROR,
  PAYMENT_QR_INSUFICIENT_FUNDS_ERROR,
  PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR,
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS,
} from '../../utils/referenceConstant';
import { getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';
import useHomeNavigation from '../../customHook/useHomeNavigation';

const PaymentQRMessageResponse = ({
  response, referenceLabels, handleRetry, subtitleMessageResponse,
}) => {
  const history = useHistory();
  const goHome = useHomeNavigation();
  const [customImgError, setCustomImgError] = useState();
  const [onSuccessPrimary, setOnSuccessPrimary] = useState();
  const [onErrorPrimary, setOnErrorPrimary] = useState();
  const [canGoHome, setCanGoHome] = useState();
  const [customCTA, setCustomCTA] = useState();

  const goToRevolvingLine = () => {
    history.push({ pathname: getPathRouteFromKey(i18n.revolvingLineDetail) });
  };

  const goToPayment = () => {
    history.push({ pathname: getPathRouteFromKey(i18n.payInstallmentKey) });
  };

  const enableDefaultGoHome = () => {
    setCanGoHome(true);
    setCustomCTA(undefined);
  };

  const init = async () => {
    setCustomImgError(undefined);
    setOnSuccessPrimary(undefined);
    if (handleRetry) {
      setOnErrorPrimary(() => handleRetry);
    }
    setCanGoHome(false);
    setCustomCTA({
      action: goHome,
      label: i18n.backToTop,
      notShowOnSuccess: true,
    });
    if (referenceLabels === PAYMENT_QR_GET_INFO_ERROR) {
      setCustomImgError(qrReadErrorImg);
    } else if (referenceLabels === PAYMENT_QR_SELECT_AMOUNT_ERROR) {
      enableDefaultGoHome();
    } else if (referenceLabels === PAYMENT_QR_CUSTOMER_DEBT_ERROR) {
      setCustomImgError(qrDebtErrorImg);
      setOnErrorPrimary(() => goToPayment);
    } else if (referenceLabels === PAYMENT_QR_INSUFICIENT_FUNDS_ERROR) {
      setCustomImgError(qrInsuficientFundsErrorImg);
      setOnErrorPrimary(undefined);
    } else if (referenceLabels === PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR) {
      enableDefaultGoHome();
    } else if (referenceLabels === PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS) {
      setOnSuccessPrimary(() => goHome);
      setCanGoHome(false);
      setCustomCTA({
        action: goToRevolvingLine,
        label: i18n.PaymentQR.Final.ctaCreditLine,
        notShowOnSuccess: false,
      });
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <MessageResponse
        response={response}
        referenceLabels={referenceLabels}
        subtitleMessageResponse={subtitleMessageResponse}
        onErrorPrimary={onErrorPrimary}
        customImgError={customImgError}
        onSuccessPrimary={onSuccessPrimary}
        canGoHome={canGoHome}
        customCTA={customCTA}
        successGreen
      />
    </>
  );
};

PaymentQRMessageResponse.propTypes = {
  response: PropTypes.shape({ data: PropTypes.shape() }).isRequired,
  referenceLabels: PropTypes.string.isRequired,
  handleRetry: PropTypes.func,
  subtitleMessageResponse: PropTypes.element,
};

PaymentQRMessageResponse.defaultProps = {
  handleRetry: undefined,
  subtitleMessageResponse: undefined,
};

export default PaymentQRMessageResponse;
