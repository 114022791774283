import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import {
  getPaymentInfoQR, getInstallmentPlan, revolvineLineCreateLoanRequest, paymentQRAndActivateLoan,
} from '../api/selfieWebService';
import { usePaymentQRContext } from '../contexts/PaymentQRContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { useAuth } from '../contexts/AuthContext';
import { getError } from '../utils/functionsUtil';
import { PaymentQRRoutesList, getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import {
  PAYMENT_QR_GET_INFO_ERROR,
  PAYMENT_QR_CUSTOMER_DEBT_ERROR,
  PAYMENT_QR_SELECT_AMOUNT_ERROR,
  PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR,
  PAYMENT_QR_INSUFICIENT_FUNDS_ERROR,
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS,
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR,
} from '../utils/referenceConstant';

const OPEN_AMOUNT = 'open_amount';

const clearValueOnlyNumbers = (value) => Number(value.replace(/\D+/g, ''));

/* ************ ESCANEAR ************ */
export const usePaymentQRScanner = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const scanner = useRef();
  const videoEl = useRef();
  const qrBoxEl = useRef();
  const [qrOn, setQrOn] = useState(true);
  const { customerAccountStatus } = useCustomerAccountStatus() || {};
  const { paymentQRContext, setPaymentQRContext } = usePaymentQRContext();

  const scannerStop = () => {
    console.log('Scanner STOP');
    scanner.current.stop();
  };

  const onScanSuccess = async (result) => {
    try {
      setLoading(true);
      const qrText = result.data;

      const response = await getPaymentInfoQR(qrText);

      const qrInfo = response.data;
      if (qrInfo.amountType === OPEN_AMOUNT) {
        setPaymentQRContext((prev) => ({
          ...prev,
          sellerName: qrInfo.sellerName,
          sellerCbuCvu: qrInfo.sellerCbuCvu,
          sellerCuit: qrInfo.sellerCuit,
          transaccionId: qrInfo.transaccionId,
          amountType: qrInfo.amountType,
          textQR: qrText,
        }));
        history.push({ pathname: PaymentQRRoutesList.selectAmount });
      } else {
        if (qrInfo.amount > paymentQRContext.maxAmountAvailable) {
          throw new Error(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
        }
        setPaymentQRContext((prev) => ({
          ...prev,
          sellerName: qrInfo.sellerName,
          sellerCbuCvu: qrInfo.sellerCbuCvu,
          sellerCuit: qrInfo.sellerCuit,
          transaccionId: qrInfo.transaccionId,
          amountType: qrInfo.amountType,
          selectedAmount: moneyFormatterWithoutDecimals(qrInfo.amount),
          textQR: qrText,
        }));
        history.push({ pathname: PaymentQRRoutesList.installmentPlan });
      }
    } catch (error) {
      console.log(error);
      if (error?.message === PAYMENT_QR_INSUFICIENT_FUNDS_ERROR) {
        setErrorMessage(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
      } else {
        setErrorMessage(PAYMENT_QR_GET_INFO_ERROR);
      }
      setResponseError(getError(undefined));
      setLoading(false);
    } finally {
      scannerStop();
    }
  };

  const onScanFail = () => {
    console.log('Error reading QR');
  };

  const scannerInit = () => {
    console.log('INIT SCANNER');
    // Instantiate the QR Scanner
    console.log('Instantiate the QR Scanner');
    if (!scanner.current) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // This is the camera facing mode. In mobile devices, "environment"
        // means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // This will help us position our "QrFrame.svg" so that user can only scan
        // when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // This will produce a yellow (default color) outline around the qr code that we scan,
        // Showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // A custom div which will pair with "highlightScanRegion" option above.
        // This gives us full control over our scan region.
        overlay: qrBoxEl.current || undefined,
      });
    }
    scanner.current.start()
      .then(() => {
        console.log('Scanner START');
        setQrOn(true);
      })
      .catch((err) => {
        if (err) setQrOn(false);
      });
  };

  const handleBack = () => {
    history.push({ pathname: getPathRouteFromKey(i18n.revolvingLineDetail) });
    scannerStop();
  };

  const handleRetry = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (customerAccountStatus.customerIsUpToDate === false) {
      setErrorMessage(PAYMENT_QR_CUSTOMER_DEBT_ERROR);
      setResponseError(getError(undefined));
    } else if (!customerAccountStatus.customerRevolvingLine.remainingAmount
        || customerAccountStatus.customerRevolvingLine.remainingAmount === 0) {
      setErrorMessage(PAYMENT_QR_INSUFICIENT_FUNDS_ERROR);
      setResponseError(getError(undefined));
    } else {
      setPaymentQRContext({
        maxAmountAvailable: moneyFormatterWithoutDecimals(customerAccountStatus.customerRevolvingLine.remainingAmount),
      });
      scannerInit();
    }
  }, []);

  return {
    loading, responseError, errorMessage, videoEl, qrBoxEl, qrOn, handleBack, handleRetry,
  };
};

/* ************ SELECCIONAR MONTO ************ */
export const usePaymentQRSelectAmount = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [amountNotAvailableError, setAmountNotAvailableError] = useState(false);
  const { paymentQRContext, setPaymentQRContext } = usePaymentQRContext();

  const handleInputChange = (e) => {
    const { value } = e.target;
    const numberValue = clearValueOnlyNumbers(value);
    setPaymentQRContext((prev) => ({
      ...prev,
      selectedAmount: moneyFormatterWithoutDecimals(numberValue),
    }));
    const requestedAmountIsNotAvailable = numberValue > clearValueOnlyNumbers(paymentQRContext.maxAmountAvailable);
    setAmountNotAvailableError(requestedAmountIsNotAvailable);
    if (requestedAmountIsNotAvailable) {
      console.log('error amount not available');
      setIsValidAmount(false);
    } else if (numberValue === '0' || numberValue === '00' || numberValue === '' || numberValue === undefined) {
      console.log('error amount is invalid');
      setIsValidAmount(false);
    } else {
      console.log('amount is valid');
      setIsValidAmount(true);
    }
  };

  const handleBack = () => {
    history.push({ pathname: PaymentQRRoutesList.home });
  };

  const handleContinue = () => {
    history.push({ pathname: PaymentQRRoutesList.installmentPlan });
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      if (paymentQRContext.amountType !== OPEN_AMOUNT) {
        throw new Error();
      }
      setPaymentQRContext((prev) => ({
        ...prev,
        selectedAmount: moneyFormatterWithoutDecimals(0),
      }));
    } catch (error) {
      setErrorMessage(PAYMENT_QR_SELECT_AMOUNT_ERROR);
      setResponseError(getError(undefined));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    responseError,
    errorMessage,
    handleContinue,
    handleBack,
    handleInputChange,
    isValidAmount,
    amountNotAvailableError,
  };
};

/* ************ PLAN DE CUOTAS ************ */
export const usePaymentQRInstallmentPlan = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [installmentPlan, setInstallmentPlan] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState();
  const { paymentQRContext, setPaymentQRContext } = usePaymentQRContext();
  const { currentUser } = useAuth();

  const selectInstallment = (installment) => {
    setSelectedInstallment(installment);
  };

  const isSelected = (installment) =>
    (selectedInstallment && selectedInstallment.installment === installment.installment) || false;

  const handleBack = () => {
    history.push({
      pathname:
      paymentQRContext?.amountType === OPEN_AMOUNT ? PaymentQRRoutesList.selectAmount
        : PaymentQRRoutesList.home,
    });
  };

  const handleRetry = () => {
    setErrorMessage(undefined);
    setResponseError(false);
  };

  const handleContinue = async () => {
    try {
      console.log('PAYMENT QR -->');

      const createLoanRequest = {
        requestedAmount: clearValueOnlyNumbers(paymentQRContext.selectedAmount),
        installmentAmount: selectedInstallment.amount,
        installments: selectedInstallment.installment,
        lineId: selectedInstallment.lineId,
        disbursementMethod: 'PAYMENT_QR',
      };
      const loanRequestResponse = await revolvineLineCreateLoanRequest(currentUser.customerTaxId, createLoanRequest);

      console.log(JSON.stringify(paymentQRContext));
      const paymentQRRequest = {
        amount: clearValueOnlyNumbers(paymentQRContext.selectedAmount),
        sellerName: paymentQRContext.sellerName,
        sellerCbuCvu: paymentQRContext.sellerCbuCvu,
        sellerCuit: paymentQRContext.sellerCuit,
        transaccionId: paymentQRContext.transaccionId,
        textQR: paymentQRContext.textQR,
      };
      const res = await paymentQRAndActivateLoan(loanRequestResponse.data.loanId, paymentQRRequest);

      setPaymentQRContext((prev) => ({
        ...prev,
        operationId: res.data.operationId,
      }));
      setErrorMessage(PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS);
      setResponseError({
        status: 200,
        data: {
          selectedAmount: paymentQRContext.selectedAmount,
          sellerName: paymentQRContext.sellerName,
        },
      });
    } catch (error) {
      setErrorMessage(PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_ERROR);
      setResponseError(getError(undefined));
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      console.log(JSON.stringify(paymentQRContext));
      if (!paymentQRContext.selectedAmount) {
        throw new Error();
      }
      const installmentListResp = await getInstallmentPlan(currentUser.customerTaxId, clearValueOnlyNumbers(paymentQRContext.selectedAmount));
      setInstallmentPlan(installmentListResp.data);
    } catch (error) {
      setErrorMessage(PAYMENT_QR_GET_INSTALLMENT_PLAN_ERROR);
      setResponseError(getError(undefined));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    responseError,
    errorMessage,
    handleContinue,
    handleBack,
    handleRetry,
    installmentPlan,
    selectedInstallment,
    selectInstallment,
    isSelected,
  };
};
