import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import parse from 'html-react-parser';
import {
  Button, Grid, Typography, makeStyles, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@react-hook/media-query';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { moneyFormatterWithoutDecimals, dateFormatter } from '../utils/formatterUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import {
  fillMessageWith, sendDataLayerEvent, isLocalEnvironment, isUATEnvironment,
} from '../utils/functionsUtil';
import RevolvingLineProgressBar from '../components/RevolvingLineProgressBar';
import imgRevolvingLineCardRechargeMobile from '../assets/imgs/revolving-card-new-recharge-mobile.png';
import imgRevolvingLineCardRechargeDesktop from '../assets/imgs/revolving-card-new-recharge-desktop.png';
import imgArrowRight from '../assets/imgs/arrow-right-small.svg';
import RevolvingLineDetailLoansInstallments from '../components/common/RevolvingLineDetailLoansInstallments';
import { getCustomerRevolvingLine } from '../utils/loginUtil';
import { useAuth } from '../contexts/AuthContext';
import { saveCustomerAccountStatus } from '../api/userStorage';
import Loading from '../components/common/Loading';

const useStyles = makeStyles((theme) => ({

  parentDiv: {
    display: 'grid',
  },
  divHeaderTitle: {
    gridArea: '1 / 1 / 3 / 5',
    backgroundColor: theme.palette.primary.main,
    paddingTop: '25px',
    paddingBottom: '100px',
  },
  divHeaderAmounts: {
    gridArea: '2 / 2 / 4 / 4',
    marginLeft: '5%',
    marginRight: '5%',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    borderRadius: '12px',
    border: '1px groove #e0e0e0',
    backgroundColor: 'white',
  },
  divBody: {
    gridArea: '4 / 2 / 5 / 4',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: '10px',
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily: 'Poppins',
    color: 'white',
  },
  listBulletUsed: {
    color: '#e72064',
    fontSize: '20px',
  },
  listBulletMax: {
    color: '#29CC6A',
    fontSize: '20px',
  },
  listText: {
    color: '#565656',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
  },
  listAmount: {
    color: '#565656',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Poppins',
  },
  textCTA: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    paddingLeft: '40px',
    color: '#424242',
  },
  textArrowCTA: {
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: '14px',
    color: '#e72064',
    textAlign: 'center',
  },
  textRegularizeAccount: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#424242',
  },
  alignCenter: {
    margin: 'auto',
    textAlign: 'center',
  },
  separator: {
    border: '1px groove #e0e0e0',
    width: '100%',
  },
  card: {
    border: '1px groove ',
    borderRadius: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '20px',
  },
  nextDueDateTitle: {
    textAlign: 'left',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: '18px',
  },
  installmentAmount: {
    textAlign: 'left',
    fontWeight: '600',
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  installmentDueDate: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#212121',
  },
  lastLoansTitle: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
  },
}));

const RevolvingLineDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { currentUser } = useAuth();
  const { customerAccountStatus } = useCustomerAccountStatus();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const [loading, setLoading] = useState(true);
  const [customerRevolvingLine, setCustomerRevolvingLine] = useState();

  const goToPay = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const goToPaymentQR = () => {
    history.push(getPathRouteFromKey(i18n.paymentQRKey));
  };

  const onNewCharge = () => {
    sendDataLayerEvent({
      event: 'lc_nueva_carga',
    });
    history.push(getPathRouteFromKey(i18n.revolvingLine));
  };

  const goToRevolvingLineDetailInstallments = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetailInstallments));
  };

  const goToRevolvingLineDetailLoans = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetailLoans));
  };

  const init = async () => {
    try {
      setLoading(true);
      const revolvingData = await getCustomerRevolvingLine(currentUser.customerTaxId);
      customerAccountStatus.customerRevolvingLine = revolvingData;
      setCustomerRevolvingLine(revolvingData);
      saveCustomerAccountStatus(customerAccountStatus);
      setCustomerAccountStatus(customerAccountStatus);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {loading && <Loading />}
      {customerRevolvingLine && !loading && (
      <>
        <div className={classes.parentDiv}>

          <div className={classes.divHeaderTitle}>
            <Typography className={classes.headerTitle}>
              {i18n.RevolvingLine.Header.title}
            </Typography>
          </div>

          <div
            className={classes.divHeaderAmounts}
            style={{ filter: customerAccountStatus.customerIsUpToDate === false ? 'grayscale(100%)' : 'grayscale(0%)' }}
          >
            <Grid container>

              {/* progress bar */}
              <Grid item xs={8}>
                <RevolvingLineProgressBar
                  maxAmount={customerRevolvingLine.maxAmount}
                  remainingAmount={customerRevolvingLine.remainingAmount}
                />
              </Grid>

              {/* amounts */}
              <Grid item xs={4}>
                <Box textAlign="left">
                  <ul style={{ paddingLeft: '25px' }}>
                    <li className={classes.listBulletUsed}>
                      <Typography className={classes.listText}>
                        {i18n.RevolvingLine.Detail.Header.usedAmount}
                      </Typography>
                      <Typography className={classes.listAmount}>
                        {moneyFormatterWithoutDecimals(customerRevolvingLine.maxAmount
                    - customerRevolvingLine.remainingAmount)}
                      </Typography>
                    </li>
                    <li className={classes.listBulletMax}>
                      <Typography className={classes.listText}>
                        {i18n.RevolvingLine.Detail.Header.maxAmount}
                      </Typography>
                      <Typography className={classes.listAmount}>
                        {moneyFormatterWithoutDecimals(customerRevolvingLine.maxAmount)}
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>

              {/* linea horizontal */}
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <hr className={classes.separator} />
              </Grid>

              {/* CTA */}
              <Grid
                item
                xs={12}
                className={classes.alignCenter}
                style={{
                  borderRadius: '12px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  backgroundColor: '#FFF9E3',
                  backgroundImage: isMobile ? `url(${imgRevolvingLineCardRechargeMobile})`
                    : `url(${imgRevolvingLineCardRechargeDesktop})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'left',
                }}
              >
                <Typography className={classes.textCTA}>
                  {i18n.RevolvingLine.Detail.newChargeTitle}
                </Typography>
                <Button
                  variant="tertiary"
                  onClick={onNewCharge}
                  style={{ padding: '0px' }}
                  disabled={customerAccountStatus.customerIsUpToDate === false}
                >
                  <ListItem style={{ padding: '0px' }}>
                    <ListItemText
                      primary={(
                        <Typography className={classes.textArrowCTA}>
                          {i18n.RevolvingLine.Detail.newChargeCTA}
                        </Typography>
                  )}
                    />
                    <ListItemIcon style={{ minWidth: '24px' }}>
                      <img src={imgArrowRight} alt="back" width="20px" />
                    </ListItemIcon>
                  </ListItem>
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.divBody}>
            <Grid container>
              {/* QR CODE SCAN */}
              {(isLocalEnvironment() || isUATEnvironment()) && (
                <Grid
                  item
                  xs={12}
                  className={classes.alignCenter}
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    variant="tertiary"
                    onClick={() => goToPaymentQR()}
                  >
                    <Typography>
                      Escanear QR
                    </Typography>
                  </Button>
                </Grid>
              )}
              {customerAccountStatus.customerIsUpToDate === false && (
                <>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Alert severity="info" style={{ border: '1px solid #3988FF' }}>
                      <Typography align="left" className={classes.textRegularizeAccount}>
                        {i18n.RevolvingLine.Detail.regularizeAcctStatus}
                      </Typography>
                    </Alert>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'initial', marginTop: '20px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={goToPay}
                      fullWidth
                    >
                      {i18n.customerDebtorLabelPayButton}
                    </Button>
                  </Grid>
                </>
              )}
              {customerRevolvingLine.nextExpirationInfo && (
                <>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography className={classes.nextDueDateTitle}>
                      {i18n.RevolvingLine.Detail.nextDueDateTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '25px' }}>
                    <Grid container className={classes.card}>
                      <Grid item xs={7}>
                        <Typography className={classes.installmentAmount}>
                          {parse(fillMessageWith(i18n.RevolvingLine.Detail.installmentAmount,
                            moneyFormatterWithoutDecimals(
                              customerRevolvingLine.nextExpirationInfo.amount,
                            )))}
                        </Typography>
                        <Typography className={classes.installmentDueDate}>
                          {fillMessageWith(i18n.RevolvingLine.Detail.installmentDueDate,
                            dateFormatter(customerRevolvingLine.nextExpirationInfo.vto))}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} style={{ textAlign: 'right' }}>
                        <Button
                          variant="tertiary"
                          onClick={() => goToRevolvingLineDetailInstallments()}
                          style={{ padding: '0px', height: '100%' }}
                        >
                          <ListItem style={{ padding: '0px' }}>
                            <ListItemText
                              primary={(
                                <Typography className={classes.textArrowCTA}>
                                  {i18n.RevolvingLine.Detail.showDetailInstallments}
                                </Typography>
                              )}
                            />
                            <ListItemIcon style={{ minWidth: '24px' }}>
                              <img src={imgArrowRight} alt="back" width="20px" />
                            </ListItemIcon>
                          </ListItem>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {customerRevolvingLine.recharges && (
                <>
                  <Grid item xs={8}>
                    <Box mt={3} />
                    <Typography className={classes.lastLoansTitle}>
                      {i18n.RevolvingLine.Detail.lastloansTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '10px' }}>
                    <Button
                      variant="tertiary"
                      onClick={() => goToRevolvingLineDetailLoans()}
                      style={{ padding: '0px', height: '100%' }}
                    >
                      <Typography className={classes.textArrowCTA}>
                        {i18n.RevolvingLine.Detail.loansShowAll}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* last 3 loans */}
                  <RevolvingLineDetailLoansInstallments
                    loanInstallments={[...customerRevolvingLine.recharges].reverse().slice(0, 3)}
                  />
                </>
              )}
            </Grid>
          </div>

        </div>
      </>
      )}
    </>
  );
};

export default RevolvingLineDetail;
