import {
  makeStyles,
} from '@material-ui/core';

export const usePaymentQRStyles = makeStyles((theme) => ({
  body: {
    marginTop: '40px',

    paddingLeft: '50px',
    paddingRight: '50px',

    '& h1': {
      fontFamily: 'Open Sans',
      fontSize: '36px',
      fontWeight: '700',
      lineHeight: '42px',
      color: '#212121',
      marginBottom: '16px',
    },

    '& a': {
      color: '#f2025d',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',

      paddingLeft: '24px',
      paddingRight: '24px',

      '& h1': {
        fontSize: '20px',
        lineHeight: '27px',
        marginBottom: '8px',
      },
    },
  },

  ctaBox: {
    padding: '50px',

    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: '0px',
      borderTop: '1px solid lightgrey',
      padding: '20px',
      width: '-webkit-fill-available',
      backgroundColor: 'white',
      zIndex: 1000,
    },
  },

  noBorder: {
    border: 'none',
  },

  selectedCard: {
    border: '2px solid #F2025D',
    backgroundColor: '#F2025D0D',
  },

  qrReader: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
  },

  qrReaderVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  qrReaderBox: {
    width: '100% !important',
    left: '0 !important',
  },

  qrCancelBtn: {
    position: 'absolute',
    fill: 'none',
    width: '3%',
    height: '3%',
    left: '80%',
    top: '5%',
    cursor: 'pointer',
  },

  qrReaderFrame: {
    position: 'absolute',
    fill: 'none',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },

  qrReaderMessage: {
    position: 'absolute',
    fill: 'none',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%) translateY(-15%)',
    fontSize: '14px',
    color: 'white',
  },

  red: {
    color: 'red !important',
  },

}));

export default usePaymentQRStyles;
