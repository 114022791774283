import parse from 'html-react-parser';
import {
  Box, Typography, Card, CardActionArea, CardContent, Radio, Grid,
} from '@material-ui/core';
import PaymentQRArrowBack from './paymentQRCtaArrowBack';
import PaymentQRCtaBtnContinue from './paymentQRCtaBtnContinue';
import Loading from '../../components/common/Loading';
import { fillMessageWith } from '../../utils/functionsUtil';
import i18n from '../../common/i18n';
import { moneyFormatter } from '../../utils/formatterUtil';
import { usePaymentQRStyles } from '../../assets/css/paymentQRStyles';
import { usePaymentQRContext } from '../../contexts/PaymentQRContext';
import PaymentQRMessageResponse from './paymentQRMessageResponse';
import { usePaymentQRInstallmentPlan } from '../../services/paymentQRService';
import {
  PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS,
} from '../../utils/referenceConstant';

const PaymentQR = () => {
  const classes = usePaymentQRStyles();
  const { paymentQRContext } = usePaymentQRContext();
  const {
    loading, responseError, errorMessage, handleContinue, handleBack, handleRetry,
    installmentPlan, selectedInstallment, selectInstallment, isSelected,
  } = usePaymentQRInstallmentPlan();

  return (
    <>
      {loading && (
        <Loading />
      )}

      {!loading && !responseError && (
        <>
          <Grid container className={classes.body}>

            <Grid item xs={12}>
              <PaymentQRArrowBack handleBack={handleBack} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '10px' }}>
              <Typography variant="h2" align="left">
                {i18n.PaymentQR.InstallmentPlan.title}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* TODO: color gris claro y bordes redondeados */}
              <div style={{ backgroundColor: 'lightGray' }}>
                <Typography variant="h3" align="left">
                  {paymentQRContext?.sellerName}
                </Typography>
                {/* TODO: input amount con decimales como superindices */}
                <Typography variant="h1" align="left">
                  {paymentQRContext?.selectedAmount}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography align="left">
                {i18n.PaymentQR.InstallmentPlan.chooseInstallment}
              </Typography>
              {installmentPlan.map((installment) => (
                <Box mt={2} key={installment.installment} mb={2}>
                  <Card
                    className={isSelected(installment) ? classes.selectedCard : ''}
                    variant="outlined"
                  >
                    <CardActionArea onClick={() => selectInstallment(installment)}>
                      <CardContent style={{ padding: '0px' }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Radio
                            color="primary"
                            checked={isSelected(installment)}
                          />
                          <Typography>
                            {parse(fillMessageWith(
                              installment.installment > 1
                                ? i18n.PaymentQR.InstallmentPlan.installments[1]
                                : i18n.PaymentQR.InstallmentPlan.installments[0],
                              installment.installment,
                              moneyFormatter(installment.amount),
                            ))}
                          </Typography>
                          {/* TODO: CFTEA / Sin interés */}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              {/* TODO: términos y concidiones */}
              {fillMessageWith(i18n.PaymentQR.InstallmentPlan.ctaAgreements, 'https://TODO: URL')}
            </Grid>

            <Grid item xs={12} md={6}>
              <PaymentQRCtaBtnContinue
                handleContinue={handleContinue}
                disabled={!selectedInstallment}
                ctaLabel={i18n.PaymentQR.InstallmentPlan.ctaPay}
                useLoadingEffect
              />
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (
        <Grid container className={classes.body} justifyContent="center">
          <Grid item xs={10} md={6}>
            <PaymentQRMessageResponse
              response={responseError}
              referenceLabels={errorMessage}
              handleRetry={handleRetry}
              subtitleMessageResponse={
                errorMessage === PAYMENT_QR_CONFIRM_INSTALLMENT_PLAN_SUCCESS
                  ? () => (
                    <>
                      <Typography>
                        <strong>
                          {fillMessageWith(i18n.PaymentQR.Final.subtitle,
                            selectedInstallment.installment,
                            moneyFormatter(selectedInstallment.amount))}
                        </strong>
                      </Typography>
                      <Box m={1} />
                      <Typography>
                        {fillMessageWith(i18n.PaymentQR.Final.description, paymentQRContext.operationId)}
                      </Typography>
                    </>
                  )
                  : undefined
              }
            />
          </Grid>
        </Grid>
      )}

    </>
  );
};

export default PaymentQR;
