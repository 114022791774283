import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Typography,
} from '@material-ui/core';

const CardHomeOption = ({
  title, urlImage, pathOnClick, isExternalLink,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (isExternalLink) {
      window.open(pathOnClick, '_blank');
    } else {
      history.push(pathOnClick);
    }
  };

  return (
    <Card style={{ width: '100%', height: '100%' }}>
      <CardActionArea onClick={handleClick} style={{ width: '100%', height: '100%' }}>
        <CardContent style={{ width: '100%', height: '100%' }}>
          <img src={urlImage} alt={title} />
          <Typography style={{ marginTop: '8px' }}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardHomeOption.propTypes = {
  title: PropTypes.string,
  urlImage: PropTypes.string.isRequired,
  pathOnClick: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};

CardHomeOption.defaultProps = {
  title: '',
  isExternalLink: false,
};

export default CardHomeOption;
