import PropTypes from 'prop-types';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import i18n from '../../common/i18n';

const PaymentQRCtaArrowBack = ({ handleBack }) => (
  <>
    <Grid container>
      <Grid item xs={1}>
        <Button
          onClick={handleBack}
          style={{ padding: '6px 0px', color: 'black' }}
          startIcon={<ArrowBack />}
        >
          {i18n.PaymentQR.ctaBack}
        </Button>
      </Grid>
    </Grid>
  </>
);

PaymentQRCtaArrowBack.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default PaymentQRCtaArrowBack;
