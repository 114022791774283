import { useHistory } from 'react-router-dom';
import {
  Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF9E5',
    border: '1px solid #F5A623',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'left',
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
}));

const CardDebtor = () => {
  const { hasDebt, getTotalDebt } = useCustomerAccountStatus();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {hasDebt() && (
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8} className={classes.container}>
            <Typography align="left" className={classes.title}>
              {fillMessageWith(i18n.customerDebtorTotalDebt, moneyFormatter(getTotalDebt()))}
            </Typography>
            <Typography align="left">
              {i18n.customerDebtorIncludes}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '16px' }}
              onClick={() => history.push(getPathRouteFromKey(i18n.payInstallmentKey))}
            >
              {i18n.customerDebtorLabelPayButton}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CardDebtor;
