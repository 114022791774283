/* eslint-disable indent */
import {
  getEnv, URL_HELP, URL_SITE_COMMUNITY, URL_SITE_HOME, URL_SITE_US, URL_SITE_WC,
} from '../api/env';
import i18n from '../common/i18n';

export const PublicRoutesList = {
  splash: '/splash',
  installApp: '/instalar-app',
  intro: '/intro',
  welcome: '/mi-cuenta',
  loginDNIValidationStep: '/validacion-dni',
  loginIdentityResolverStep: '/validacion-cuit',
  loginPassword: '/iniciar-sesion',
  loginVerificationCodeStep: '/validacion-celular',
  loginCodeNoReception: '/sms-no-recibido',
  loginUserNotClient: '/no-activo',
  loginChangeCellphoneNumber: '/cambio-celular',
  loginExpired: '/sesion-expirada',
  updateDebitCard: '/actualizar-td',
  wikiComponents: '/wiki',
  autologin: '/autologin',
};

export const RefinancingRoutesList = {
  home: '/refinanciacion',
  installmentPlan: '/refinanciacion/plan-cuotas',
  selfie: '/refinanciacion/selfie',
  biometricResult: '/refinanciacion/biometria',
  paymentMethod: '/refinanciacion/forma-pago',
  summary: '/refinanciacion/resumen',
  final: '/refinanciacion/final',
};

export const PaymentQRRoutesList = {
  home: '/pagar-qr',
  selectAmount: '/pagar-qr/seleccionar-monto',
  installmentPlan: '/pagar-qr/plan-cuotas',
};

export const PrivateRoutesList = [
  {
    key: i18n.pushNotificationSubscription,
    path: '/recibir-notificaciones',
  },
  {
    key: i18n.generatePassword,
    path: '/generar-clave',
  },
  {
    key: i18n.userProfileKey,
    path: '/mis-datos',
  },
  {
    key: i18n.myLoansKey,
    path: '/mis-prestamos',
  },
  {
    key: i18n.myInstallmentsKey,
    path: '/mis-prestamos/:idLoan',
  },
  {
    key: i18n.myInstallmentsViewAllKey,
    path: '/mis-prestamos/:idLoan/ver-todas',
  },
  {
    key: i18n.payInstallmentKey,
    path: '/pagar-cuota',
  },
  {
    key: i18n.paymentLoanSelectionKey,
    path: '/pagar-cuotas',
  },
  {
    key: i18n.PaymentMethodSelectionOnlineKey,
    path: '/pagar-cuota-online',
  },
  {
    key: i18n.HowPaymentMethodOnlineKey,
    path: '/como-pagar-cuota-online',
  },
  {
    key: i18n.PaymentDebitCardFormKey,
    path: '/pagar-cuota-td',
  },
  {
    key: i18n.PaymentMPFormKey,
    path: '/pagar-cuota-mp',
  },
  {
    key: i18n.paymentQRKey,
    path: '/pagar-qr',
  },
  {
    key: i18n.sendPaymentReceiptKey,
    path: '/enviar-comprobante',
  },
  {
    key: i18n.prePaidLoanKey,
    path: '/cancelacion-anticipada',
  },
  {
    key: i18n.customerFreeDebtKey,
    path: '/libre-deuda',
  },
  {
    key: i18n.loanPaymentVouchersKey,
    path: '/descargar-cuponera',
  },
  {
    key: i18n.moreProductsKey,
    path: '/otros-servicios',
  },
  {
    key: i18n.myBillsKey,
    path: '/mis-facturas',
  },
  {
    key: i18n.questionsKey,
    path: '/consultas',
  },
  {
    key: i18n.homeKey,
    path: '/inicio',
  },
  {
    key: i18n.newHomeKey,
    path: '/nueva-home',
  },
  {
    key: i18n.logoutKey,
    path: '/cerrar-sesion',
  },
  {
    key: i18n.PaymentStatus,
    path: '/estado-pago',
  },
  {
    key: i18n.myCardActivateKey,
    path: '/mi-tarjeta/activar',
  },
  {
    key: i18n.myCardDetailsKey,
    path: '/mi-tarjeta/detalles',
  },
  {
    key: i18n.myCardChangePinKeyPreview,
    path: '/mi-tarjeta/cambiar-pin',
  },
  {
    key: i18n.myCardChangePinKey,
    path: '/mi-tarjeta/cambiar-pin/validacion',
  },
  {
    key: i18n.myCardDisableKey,
    path: '/mi-tarjeta/bloquear',
  },
  {
    key: i18n.myCardReprintKey,
    path: '/mi-tarjeta/reimprimir',
  },
  {
    key: i18n.myCardKey,
    path: '/mi-tarjeta',
  },
  {
    key: i18n.newLoanKey,
    path: '/nuevo-prestamo',
  },
  {
    key: i18n.surveyKey,
    path: '/encuesta',
  },
  {
    key: i18n.helpMenuKey,
    path: '/ayuda',
  },
  {
    key: i18n.myPaymentsMenuKey,
    path: '/mis-pagos',
  },
  {
    key: i18n.revolvingLineOffer,
    path: '/oferta-linea-revolvente',
  },
  {
    key: i18n.revolvingLine,
    path: '/linea-revolvente',
  },
  {
    key: i18n.revolvingLineDetail,
    path: '/linea-revolvente-detalle',
  },
  {
    key: i18n.revolvingLineDetailInstallments,
    path: '/linea-revolvente-detalle-cuotas',
  },
  {
    key: i18n.revolvingLineDetailLoans,
    path: '/linea-revolvente-detalle-cargas',
  },
  {
    key: i18n.revolvingLineDismiss,
    path: '/linea-revolvente-cancelacion',
  },
  {
    key: i18n.webAuthnRegister,
    path: '/modo-desbloqueo',
  },
];

export const getPathRouteFromKey = (key) => {
  const route = PrivateRoutesList.find((rou) => rou.key === key);
  return route.path;
};

export const getPathRouteFromKeyAndParameters = (key, parameters) => {
  const regexParameter = new RegExp(':[A-z|0-9]*');
  let route = getPathRouteFromKey(key);
  parameters.forEach((element) => {
    // Es como el "".includes() pero evalua la regex en el string
    if (regexParameter.test(route)) {
      route = route.replace(regexParameter, element);
    }
  });
  return route;
};

export const isDirectPathRoute = (pathname) => {
  if (pathname === PublicRoutesList.updateDebitCard || pathname === PublicRoutesList.autologin) {
    return true;
  }
  return false;
};

const isLoginPathWithConditions = (pathname) => (
  pathname === PublicRoutesList.loginIdentityResolverStep
  || pathname === PublicRoutesList.loginCodeNoReception
  || pathname === PublicRoutesList.loginUserNotClient
  || pathname === PublicRoutesList.loginVerificationCodeStep
);

export const getPathRouteIfRequireRedirect = (pathname, loginData) => {
  if (isLoginPathWithConditions(pathname)) {
    if (loginData === undefined || loginData.dni === undefined || loginData.users === undefined) {
      return PublicRoutesList.loginDNIValidationStep;
    }
    if (loginData.customerTaxId === undefined) {
      return PublicRoutesList.loginIdentityResolverStep;
    }
  }
  return pathname;
};

export const MenuItemsSection1 = [
  {
    text: i18n.myLoansMenuLabel,
    path: getPathRouteFromKey(i18n.myLoansKey),
  },
  {
    text: i18n.payInstallmentMenuLabel,
    path: getPathRouteFromKey(i18n.payInstallmentKey),
  },
];
export const MenuItemsSection2 = [
  {
    text: i18n.myCardMenuLabel,
    path: getPathRouteFromKey(i18n.myCardKey),
  },
  {
    text: i18n.resolvingLineOfferMenuLabel,
    path: getPathRouteFromKey(i18n.revolvingLineOffer),
  },
  {
    text: i18n.newLoanRequestMenuLabel,
    path: getPathRouteFromKey(i18n.newLoanKey),
  },
  {
    text: i18n.moreProductsMenuLabel,
    path: getPathRouteFromKey(i18n.moreProductsKey),
  },
];
export const MenuItemsSection3 = [
  {
    text: i18n.myPaymentsMenuLabel,
    path: getPathRouteFromKey(i18n.myPaymentsMenuKey),
  },
  {
    text: i18n.loanPaymentVouchersMenuLabel,
    path: getPathRouteFromKey(i18n.loanPaymentVouchersKey),
  },
  {
    text: i18n.customerFreeDebtMenuLabel,
    path: getPathRouteFromKey(i18n.customerFreeDebtKey),
  },
  {
    text: i18n.myBillsMenuLabel,
    path: getPathRouteFromKey(i18n.myBillsKey),
  },
];
export const MenuItemsSection4 = [
  {
    text: i18n.helpMenuLabel,
    path: getPathRouteFromKey(i18n.helpMenuKey),
  },
  {
    text: i18n.questionsMenuLabel,
    path: getPathRouteFromKey(i18n.questionsKey),
  },
];
export const MenuItemsSection5 = [
  {
    text: i18n.userProfileMenuLabel,
    path: getPathRouteFromKey(i18n.userProfileKey),
  },
  {
    text: i18n.logoutMenuLabel,
    path: getPathRouteFromKey(i18n.logoutKey),
  },
];

const headerMenuWhereToBuy = [
  {
    text: i18n.navBarWcLabel,
    path: getEnv(URL_SITE_WC),
  },
];

export const MenuNavBarItems = [
  {
    text: i18n.navBarHomeLabel,
    path: getEnv(URL_SITE_HOME),
  },
  {
    text: i18n.navBarWhereToBuyLabel,
    subMenu: headerMenuWhereToBuy,
  },
  {
    text: i18n.navBarUsLabel,
    path: getEnv(URL_SITE_US),
  },
  {
    text: i18n.navBarCommunityLabel,
    path: getEnv(URL_SITE_COMMUNITY),
  },
  {
    text: i18n.navBarHelpLabel,
    path: getEnv(URL_HELP),
  },
];

export const MenuSideBarItems = [
  {
    text: i18n.navBarHomeLabel,
    path: getEnv(URL_SITE_HOME),
  },
  {
    text: i18n.navBarWcLabel,
    path: getEnv(URL_SITE_WC),
  },
  {
    text: i18n.navBarUsLabel,
    path: getEnv(URL_SITE_US),
  },
  {
    text: i18n.navBarCommunityLabel,
    path: getEnv(URL_SITE_COMMUNITY),
  },
  {
    text: i18n.navBarHelpLabel,
    path: getEnv(URL_HELP),
  },
];
